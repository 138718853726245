import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
var PreviewAnyFile = /** @class */function (_super) {
  __extends(PreviewAnyFile, _super);
  function PreviewAnyFile() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  PreviewAnyFile.prototype.preview = function (url) {
    return cordova(this, "preview", {}, arguments);
  };
  PreviewAnyFile.prototype.previewPath = function (path, opt) {
    if (opt === void 0) {
      opt = {};
    }
    return cordova(this, "previewPath", {
      "observable": true,
      "callbackOrder": "reverse"
    }, arguments);
  };
  PreviewAnyFile.prototype.previewBase64 = function (base64, opt) {
    if (opt === void 0) {
      opt = {};
    }
    return cordova(this, "previewBase64", {
      "observable": true,
      "callbackOrder": "reverse"
    }, arguments);
  };
  PreviewAnyFile.prototype.previewAsset = function (path, opt) {
    if (opt === void 0) {
      opt = {};
    }
    return cordova(this, "previewAsset", {
      "observable": true,
      "callbackOrder": "reverse"
    }, arguments);
  };
  PreviewAnyFile.ɵfac = /* @__PURE__ */(() => {
    let ɵPreviewAnyFile_BaseFactory;
    return function PreviewAnyFile_Factory(t) {
      return (ɵPreviewAnyFile_BaseFactory || (ɵPreviewAnyFile_BaseFactory = i0.ɵɵgetInheritedFactory(PreviewAnyFile)))(t || PreviewAnyFile);
    };
  })();
  PreviewAnyFile.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: PreviewAnyFile,
    factory: PreviewAnyFile.ɵfac
  });
  PreviewAnyFile.pluginName = "Preview/Open Any File";
  PreviewAnyFile.plugin = "cordova-plugin-preview-any-file";
  PreviewAnyFile.pluginRef = "PreviewAnyFile";
  PreviewAnyFile.repo = "https://github.com/mostafa-mansour1/previewAnyFile";
  PreviewAnyFile.install = "";
  PreviewAnyFile.installVariables = [];
  PreviewAnyFile.platforms = ["Android", "iOS"];
  PreviewAnyFile = __decorate([], PreviewAnyFile);
  return PreviewAnyFile;
}(AwesomeCordovaNativePlugin);
export { PreviewAnyFile };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && void 0;
})();
